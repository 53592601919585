.add-app-container {
  width: 100%;
  flex-direction: column;
}

/* .comment-box {
  margin-top: 1rem;
} */

.submit-box-new-comment,
.submit-box {
  width: 42rem;
  max-width: 42rem;
}

.btn-add-prompt {
  margin-top: 1rem;
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
  .submit-box {
    width: 90%;
  }
}
