.footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
}

main {
  max-width: 85rem;
  margin: 0 auto;
}

.hero {
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0 2rem 0;
}

.hero h1 {
  margin-bottom: 0rem;
  margin-top: 0rem;
}

.hero form {
  margin-top: 2rem;
}

.subheading {
  margin-top: 1rem;
}

.logo {
  font-weight: 700;
}

.container-cards {
  /* margin: 3rem 0rem; */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 2rem;
  /* justify-content: end;
  align-content: end; */
  /* display: flex;
  flex-direction: column;
  gap: 2rem; */
  /* margin-top: 2rem; */
}

.infinite-scroll-component.container-cards {
  margin-top: 0rem !important;
}

.container-cards.grid {
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-width: 768px) {
  .container-cards.grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.container-cards.list {
  grid-template-columns: repeat(1, 1fr);
}

.input-search-home {
  min-width: 45rem !important;
  border: 1px solid #d9e1ec !important;
  padding: 1rem 1.5rem 1rem 2.5rem !important;
  border-radius: 5px !important;
  height: 3rem !important;
}
.dropdown-search {
  position: absolute;
  top: 17rem;
  background-color: #fff;
  border: 1px solid #ffcdb2 !important;
  border-radius: 5px;
  min-width: 45rem;
  max-width: 45rem;
}
.dropdown-search ul {
  padding-left: 0rem !important;
  margin: 0rem !important;
}
.dropdown-search ul li {
  padding: 1rem;
  border-bottom: 1px solid #ffcdb2;
}

.dropdown-search ul li:hover {
  background-color: #ffcdb2;
}

.search-icon {
  position: absolute;
  padding: 0.7rem;
}

.dropdown-search ul:has(.search-no-apps) {
  padding: 1rem;
}

.home .search-icon {
  position: absolute;
  padding: 1rem;
}

.container-topics {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 0.5rem;
}

.button-topics {
  display: none;
}

form.home {
  display: none;
}

@media only screen and (max-width: 768px) {
  .container-topics {
    display: none;
  }
  .container-topics.show {
    display: flex;
    animation: slide-in 500ms;
  }

  .button-topics {
    display: block;
  }
}

.container-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
  gap: 0.5rem;
  /* padding-right: 2rem; */
  margin: 2rem 0rem;
}
@media only screen and (max-width: 768px) {
  .container-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
}
.container-scroll {
  display: flex;
  justify-content: center;
}
.infinite-scroll-component__outerdiv {
  width: 100%;
}
.filters {
  max-width: 35rem;

  padding: 1rem 2rem !important;
}
.filters ul {
  text-align: left;
  margin-top: 1rem;
}

.filters li {
  list-style-type: none !important;
  margin-bottom: 0.5rem;
}

@keyframes slide-in {
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-20%);
  }
}

.container-details-section.show,
.container-topics-section.show {
  display: flex !important;
  transition: 1s;
  left: 0;
  animation: slide-in 500ms;
  margin-bottom: 2rem;
}

.container-details-section,
.container-topics-section {
  display: none;
  justify-content: center;
  animation: slide-in 500ms;
}

.container-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.container-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.filter-grid {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .hero.apps {
    margin: 2rem auto;
    padding: 0rem;
  }
  .hero form {
    display: none;
    /* width: 90%;
    height: 100%;
    padding: 2rem 0rem; */
  }
  .container-cards {
    grid-template-columns: repeat(1, 1fr);
    margin: 2rem auto;
    gap: 1rem;
    width: 90%;
  }

  .subheading {
    margin-top: 1rem;
  }

  .hero form {
    width: 90%;
  }

  .hero h1 {
    margin-top: 0rem;
    font-size: 1.7rem;
  }
  .input-search-home {
    min-width: 0% !important;
    width: 100%;
  }

  .dropdown-search {
    top: 26.5rem;
    min-width: 20rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container-cards {
    grid-template-columns: repeat(2, 1fr);
    margin: 2rem auto;
    gap: 1rem;
    width: 90%;
  }
  .input-search-home {
    width: 65%;
  }

  .container-cards.small-cards {
    width: 100%;
  }
}
