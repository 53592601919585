@import '~react-image-gallery/styles/css/image-gallery.css';

.landing-page-container {
  color: #687808;
}

.container-image-gallery-dealview {
  max-width: 42rem;
  margin: 0 auto;
}
