.container-appview {
  text-align: left;
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: start;
}
.container-bookmark {
  display: flex;
  align-items: center;
}

.container-bookmark,
.container-title {
  justify-content: space-between;
  width: 100%;
}
.container-description {
  width: 100%;
}
.container-rating,
.container-title {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.container-appview p {
  line-height: 1.5rem;
}

.container-appview-buttons,
.container-appview-codes {
  gap: 1rem;
  display: flex;
}

.container-appview-codes-users {
  flex-direction: column;
  display: flex;
  gap: 1rem;
}

.container-appview-codes-users span {
  font-size: 14px;
}

.codes-added-by-mobile {
  display: none !important;
}

.container-appview-codes {
  align-items: center;
}
.container-deal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.prompt-title-view {
  font-size: 1.4rem;
}

.icons-apps-page {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.container-details {
  padding: 1.5rem;
  background-color: #ffe5d9;
  border-radius: 5px;
  border: 1px solid #efefef;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 0.9rem;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.container-details p:not(.container-details.cta p) {
  margin: 0.5rem 0;
}

.container-details h2 {
  margin-top: 0rem;
}

.container-tags {
  display: flex;
  gap: 1rem;
}

.container-alternatives {
  width: 100%;
}

.searches {
  margin-top: 1rem;
}

.button-back {
  margin-top: 3rem;
}

.comment-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1.5rem;
  gap: 0.7rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.comment-author-date {
  font-size: 0.75rem;
  color: #828282;
}

.simple-link {
  text-decoration: underline;
}

.appview-image,
.appview-icon {
  max-height: 15rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.appview-image {
  border-radius: 0.5rem;
}

.appview-icon {
  border-radius: 1.5rem;
}

.small-cards {
  margin-top: 1rem;
}

.cta {
  flex-direction: row;
  align-items: center;

  gap: 20rem;
}

.app-description {
  white-space: pre-line;
}

.logo-store {
  width: 8rem;
}

.container-store-logos {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.badges {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  align-items: center;
}

.container-badges {
  gap: 1rem;
}

.container-alternatives h2 {
  margin-top: 0rem;
}

.container-title h2 {
  margin-top: 0rem;
}
img.copy-referral-code {
  margin-left: 0.2rem;
  margin-bottom: -0.2rem;
}

img.copy-referral-code.smaller-icon {
  width: 1rem;
}

.icon-copy {
  width: 1rem;
}

.container-codes {
  width: 100%;
}

.container-codes-users {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.container-related-searches {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.p-no-margin {
  margin: 0rem !important;
}

.badges-keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: flex-start;
}

.h-no-margin {
  margin-top: 0rem;
}

.h-no-margin-bottom {
  margin-bottom: 0rem;
}

.container-comments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cta-code-form {
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.button-container-add-code {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.25rem;
  padding: 0rem;
  box-shadow: none;
}

@media only screen and (min-width: 769px) {
  .container-description h2 {
    margin-bottom: 0rem;
  }
}

.container-appview-box h2 {
  margin-top: 0rem;
}

.container-description p:last-child {
  margin-bottom: 0rem;
}
@media only screen and (max-width: 768px) {
  .badges {
    align-items: self-start;
  }
  .container-title {
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem;
  }
  .cta {
    flex-direction: column;

    gap: 0rem;
  }

  .cta-gap {
    gap: 1rem;
  }
  .cta p {
    text-align: center;
  }
  .container-tags {
    flex-direction: column;
    gap: 1rem;
  }
  .container-store-logos {
    flex-direction: column;
  }
  .container-bookmark {
    flex-direction: column;
    gap: 2rem;
  }
  .container-appview-buttons,
  .container-appview-codes {
    /* flex-direction: column; */
    align-items: center;
  }

  .appview-image,
  .appview-icon {
    /* margin: 0 auto; */
    max-height: 10rem;
  }

  .container-comments {
    width: 100%;
  }

  .container-appview .submit-box {
    width: 100% !important;
  }
  .comment-box {
    padding: 1.5rem;
  }

  .container-cards.small-cards {
    width: 100%;
  }

  .container-appview .header h3 {
    display: none;
  }

  .container-store-logos {
    gap: 2rem;
  }

  .container-deal-actions {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .container-codes-users {
    gap: 0.5rem;
    align-items: stretch;
    flex-direction: column;
  }
  .badges {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .container-appview {
    width: 90%;
    margin: 0 auto;
  }
  .container-appview h1 {
    font-size: 1.75rem;
  }
}

.container-appview h1 {
  margin-bottom: 0rem;
}

@media only screen and (min-width: 769px) {
  /* .container-appview h1 {
    margin: 0 !important;
  } */

  .container-appview .header h3 {
    margin-bottom: 0rem;
  }

  .container-details.cta p {
    margin-bottom: 0rem;
  }
}
.container-appview > .container-details > .container-tags:nth-child(1) p {
  margin: 0rem;
}

.container-appview > .container-details > .container-tags:nth-child(2) p {
  margin: 0rem;
}
